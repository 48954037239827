// TAGS
// ==============================

$tag-text-color: $color-white !default;
$tag-default-color: $color-gray-500 !default;
$tag-colors: (
    'blue'    : $color-blue-500,
    'red'     : $color-red-500,
    'green'   : $color-green-500,
    'orange'  : $color-orange-500,
) !default;

.tags {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    .tag {
        margin-right: 5px;
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
        color: $tag-text-color;
        background: $tag-default-color;
        box-shadow: 0 2px 4px $color-gray-200;

        a {
            display: block;
            color: $color-white;
        }

    }

    .tag-rounded {
        border-radius: 16px;
    }

    @each $name, $color in $tag-colors {
        .tag-#{$name} {
            background: $color;
        }
    }
}

// CARDS
// ==============================

$card-shadow: true !default;
$card-title-font-size: 24px !default;
$card-bg-color: $color-white !default;

.card {
    margin: 15px;
    padding: 30px;
    background: $card-bg-color;
    overflow: hidden;
    border-radius: 5px;

    @if $card-shadow {
        box-shadow: 0 4px 12px rgba($color-gray-300, 0.5);
    }

}

.card-title {
    font-size: $card-title-font-size;
    margin-bottom: 5px;
}

.card-actions {
    display: flex;
    align-items: center;
    margin-top: 30px;
    list-style: none;
    justify-content: space-around;

    & > * {
        margin-bottom: 0;
    }
}

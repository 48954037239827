// PANELS
// =============================

$panel-shadow: true !default;
$panel-bg-color: $color-white !default;
$panel-head-color: $color-gray-50 !default;
$panel-footer-color: $color-gray-50 !default;

.panel {
    background: $panel-bg-color;
    border-radius: 5px;
    overflow: hidden;
    margin: 30px 0;

    @if $panel-shadow {
        box-shadow: 0 4px 12px rgba($color-gray-300, 0.5);
    }
}

.panel-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px 15px;
    background: $panel-head-color;

    .panel-title {
        font-size: 28px;
    }
}

.panel-body {
    padding: 30px 15px;
}

.panel-footer {
    padding: 15px;
    background: $panel-footer-color;

    & > * {
        margin-bottom: 0;
    }
}

// TABS
// ==============================

$tab-active-color: $brand-color !default;
$tab-border-color: $color-gray-300 !default;
$tab-hover-text-color: $color-gray-800 !default;
$tab-active-text-color: $color-gray-600 !default;
$tab-inactive-text-color: $color-gray-400 !default;

.tabs {
    list-style: none;
    display: flex;
    margin: 15px 0 30px;

    .tab {
        padding: 8px 45px;
        border-bottom: 1px solid $tab-border-color;
        color: $tab-inactive-text-color;

        &.active {
            border-top: 2px solid $tab-active-color;
            border-left: 1px solid $tab-border-color;
            border-right: 1px solid $tab-border-color;
            border-bottom: none;
            color: $tab-active-text-color;
        }

        &:focus,
        &:hover {
            opacity: 1;
            color: $tab-hover-text-color;
        }
    }
}

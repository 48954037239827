// MODALS
// ==============================

$modal-shadow: true !default;
$modal-bg-color: $color-white !default;
$modal-mask-color: $color-gray-700 !default;
$modal-content-bg-color: $color-gray-200 !default;

.modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($modal-mask-color, 0.5);
    z-index: 10;
}

.modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 540px;
    transform: translate(-50%, -50%);
    background: $modal-bg-color;
    border-radius: 5px;
    overflow: hidden;

    @if $modal-shadow {
        box-shadow: 0 4px 12px rgba($color-gray-600, 0.5);
    }
}

.modal-head {
    padding: 10px 15px;

    .modal-title {
        font-size: 24px;
    }
}

.modal-body {
    flex: 1;
    padding: 30px 15px;
    background: $modal-content-bg-color;
}

.modal-footer {
    padding: 15px;

    & > * {
        margin-bottom: 0;
    }
}

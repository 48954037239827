// BREAKPOINT MIXINS
// ==============================

@mixin breakpoint-min($bp) {
    @media (min-width: $bp) {
        @content;
    }
}

@mixin breakpoint-max($bp) {
    @media (max-width: $bp) {
        @content;
    }
}

@mixin breakpoint-min-max($bp1, $bp2) {
    @media (min-width: $bp1) and (max-width: $bp2) {
        @content;
    }
}

// BASE
// ==============================

$body-bg-color: $color-white !default;
$brand-color: $color-blue-800 !default;

* {
    margin: 0;
    padding: 0;
}

html {
    box-sizing: border-box;
}

*, *::after, *::before {
    box-sizing: inherit;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $body-bg-color;
}

main {
    flex: 1;
}

// TOOLTIPS
// ==============================

$tooltip-bg-color: $color-gray-800 !default;
$tooltip-underline-color: $color-gray-400 !default;

.tooltip {
    display: inline-block;
    position: relative;
    border-bottom: 1px dashed $tooltip-underline-color;

    &:focus,
    &:hover {
        cursor: pointer;

        .tooltip-text {
            display: block;
        }
    }
}

.tooltip-text {
    display: none;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 300px;
    padding: 10px 15px;
    color: $color-white;
    text-align: center;
    background: $tooltip-bg-color;
    border-radius: 5px;
    box-shadow: 0 2px 4px $color-gray-500;

    &::after {
        position: absolute;
        content: '';
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 8px;
        border-style: solid;
        border-color: $tooltip-bg-color transparent transparent;
    }
}

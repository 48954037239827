// PAGINATION
// ==============================

$pagination-bg-color: $color-white !default;
$pagination-active-color: $brand-color !default;
$pagination-border-color: $color-gray-300 !default;

.pagination {
    display: flex;
    list-style: none;
    margin: 15px 0;

    li {
        margin: 0 5px;

        a {
            display: block;
            padding: 5px 10px;
            border-radius: 5px;
            background: $pagination-bg-color;
            border: 1px solid $pagination-border-color;

            &.active,
            &:focus,
            &:hover {
                opacity: 1;
                border-color: $pagination-active-color;
            }

            &.active {
                background: $pagination-active-color;
                color: $color-white;
            }

            &:active {
                transform: scale(0.95);
            }
        }
    }
}
